import React from "react"
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
         Many More Happy Returns of the Day!
        </p>
        <p> 
         You are the Best thing that happened till Date
        </p> 
         I wish you keep smiling Every Day
        <p>
         There are many Doors that come we open and break each Day
        </p>
        <p>
         I Love You and Once More Happy BirthDay
        </p>                   
      </header>
    </div>
  );
}

export default App;
